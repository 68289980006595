import Head from 'next/head'
import 'bootstrap/dist/css/bootstrap.css' // bootstrap@5.1.3 
import 'react-toastify/dist/ReactToastify.css'
import '../styles/globals.css'
import '../public/js/theme.js'
import {ErrorBoundary} from '../components/error-handler/error-boundary'
import {ToastContainer} from 'react-toastify'
import {LogError} from '../config'

import '../public/css/jalalidatepicker.min.css'

if(typeof window !== 'undefined') {
	window.addEventListener("unhandledrejection", (event) => {
		LogError(event.reason,null);
	});
	window.addEventListener("error", (event) => {
		LogError(`${event.type}: ${event.message}`,null);
	});
}

export default function MyApp({ Component, pageProps }) {
	return (
		<>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
			</Head>
			<ErrorBoundary>
				<Component {...pageProps}/>
				<ToastContainer/>
			</ErrorBoundary>
		</>
	)
}
