function setColors(isDark) {
	var root = document.querySelector(':root')
	if(isDark){
		root.style.setProperty('--background-color', "rgb(14, 17, 20)")
		root.style.setProperty('--sub-background-color', "rgb(21, 24, 27)")
		root.style.setProperty('--text-color', "rgba(255, 255, 255, 0.87)")
		root.style.setProperty('--sub-text-color', "rgba(255, 255, 255, 0.6)")
		root.style.setProperty('--patern-opacity', "0.5")
		root.style.setProperty('--patern-drop-shadow', "unset")
		root.style.setProperty('--grey-light', "rgb(26, 28, 30)")
		root.style.setProperty('--grey-dark', "rgb(235, 235, 235)")
		root.style.setProperty('--grey-icon-color', "rgb(245, 245, 245)")
		root.style.setProperty('--loading-background', "rgb(30, 33, 37)")
		root.style.setProperty('--loading-shadow', "rgba(0, 0, 0, 0.5)")
		root.style.setProperty('--invoice-grey', "rgb(50, 50, 50)")
	}else{
		root.style.setProperty('--background-color', "rgb(255, 255, 255)")
		root.style.setProperty('--sub-background-color', "rgb(240, 240, 240)")
		root.style.setProperty('--text-color', "rgba(0, 0, 0, 0.87)")
		root.style.setProperty('--sub-text-color', "rgba(0, 0, 0, 0.6)")
		root.style.setProperty('--patern-opacity', "1")
		root.style.setProperty('--patern-drop-shadow', "drop-shadow(0px 0px 0px var(--primary))")
		root.style.setProperty('--grey-light', "rgb(235, 235, 235)")
		root.style.setProperty('--grey-dark', "rgb(65, 65, 65)")
		root.style.setProperty('--grey-icon-color', "rgb(55, 55, 55)")
		root.style.setProperty('--loading-background', "rgb(210, 210, 210)")
		root.style.setProperty('--loading-shadow', "rgba(255, 255, 255, 0.5)")
		root.style.setProperty('--invoice-grey', "rgb(200, 200, 200)")
	}
}
if(typeof window !== 'undefined'){
	if(localStorage.getItem("theme-state") !== null){
		localStorage.getItem("theme-state") == "dark" ? setColors(true) : setColors(false)
	}else{
		matchMedia("(prefers-color-scheme:dark)").matches ? setColors(true) : setColors(false)
	}
}