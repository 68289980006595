
import {useRouter} from 'next/router'
import Siema from 'siema' //siema@1.5.1
import $ from 'jquery' //jquery@3.5.1
import {toast} from 'react-toastify'
import * as Sentry from "@sentry/nextjs"

const dev = process.env.NODE_ENV !== "production"

export const CompanyName = "اقیانوس"

export const CoinsServerIconsFolderUrl = dev ? "https://localhost:44373/icons" : "https://price-api.ocean-ex.com/icons"
export const CoinsServerImagesFolderUrl = dev ? "https://localhost:44373/images" : "https://price-api.ocean-ex.com/images"
export const CoinsSocketUrl = dev ? "ws://localhost:777/" : "wss://price-socket.ocean-ex.com/"
export const UserPanelServerUrl = dev ? "https://localhost:44316/api/UserPanel" : "https://api.ocean-ex.com/api/UserPanel"
export const UserPanelServerIconsFolderUrl = dev ? "https://localhost:44316/icons" : "https://api.ocean-ex.com/icons"
export const UserPanelServerImagesFolderUrl = dev ? "https://localhost:44316/images" : "https://api.ocean-ex.com/images"

export const ScriptsVersion = "23073112"
export const StaticImagesVersion = "23073112"
export const ImagesVersion = "23073112"

export const MinimumPasswordLegth = 8
export const SmsCodeLegth = 5
export const GoogleAuthenticatorCodeLegth = 6

export const createButtonText = "افزودن"
export const createButtonLoadingText = "در حال ثبت"
export const editButtonText = "ذخیره تغییرات"
export const editButtonLoadingText = "در حال ثبت"
export const deleteButtonText = "بله"
export const deleteButtonLoadingText = "در حال حذف"

export const Numbers = "0123456789" //۰۱۲۳۴۵۶۷۸۹

export const ConverNumbersToEnFormat = function (value) {
	return value.replace(/[٠-٩]/g, d => "٠١٢٣٤٥٦٧٨٩".indexOf(d)).replace(/[۰-۹]/g, d => "۰۱۲۳۴۵۶۷۸۹".indexOf(d))
}

export const StringReplaceAll = function (str, fromSubStr, toSubStr) {
	do
		str = str.replace(fromSubStr, toSubStr)
	while (str.includes(fromSubStr))
	return str
}

export const LogError = function (error, router) {
	if(error == "TypeError: Failed to fetch"){
		//ShowToast("error", "خطایی در برقراری ارتباط با سرور رخ داده است لطفا دوباره امتحان کنید", 8000, false, "server-connection-error")
	}else if(IsExist(error.status) && error.status == 400){
		
	}else if(IsExist(error.status) && error.status == 401){
		const options = {
			method: 'GET', 
			headers: new Headers({
				'Authorization': LocalStorageGetter("token") !== null ? LocalStorageGetter("token") : ""
			})
		}
		fetch((UserPanelServerUrl + "/Authentication/CheckLogin"), options)
		.then(async (res) => {
			if(res.status == 401){
				return res
			}
			throw res
		})
		.then((resData) => {
			LocalStorageRemover("token")
			LocalStorageRemover("userFullName")
			LocalStorageRemover("profileImageID")
			ShowToast("warning", "لطفا وارد حساب کاربری شوید", 8000 ,false, "please-login")
			router.push("/")
		})
		.catch((catchError) => {})
	}else if(IsExist(error.status) && error.status == 403){
		ShowToast("error", "آی پی شما برای اتصال صحیح نیست", 8000, false, "wroung-connected-ip")
	}else if(IsExist(error.status) && error.status == 429){
		ShowToast("error", "تعداد درخواست ها بیش از حد مجاز است لطفا چند دقیقه دیگر امتحان نمایید", 8000, false, "too-many-requests")
	}else{
		console.log(error)
		Sentry.captureMessage(new Error(error))
	}
}

export const ShowToast = function (type, text, duration, showDuplicatedToast, toastCode) {
	//info , success , warning , error , default'
	if(typeof window !== 'undefined'){
		try {
			const theme = ThemeIsDark() ? "dark" : "light"
			if(showDuplicatedToast){
				toast(text, { hideProgressBar: false, rtl: true, theme: theme, autoClose: duration, type: type, position:'top-right', className:"toast-item-layer"})
			}else if(IsExist(document.getElementById("showing-toast-code-" + toastCode)) == false){
				toast(text, { hideProgressBar: false, rtl: true, theme: theme, autoClose: duration, type: type, position:'top-right', className:"toast-item-layer", toastId:"showing-toast-code-" + toastCode})
			}
		} catch (error) {
			LogError(error,null)
		}
	}
}

export const LocalStorageSetter = function (key, value) {
	try {
		localStorage.setItem(key, value)
	} catch (error) {
		LogError(error,null)
	}
}

export const LocalStorageGetter = function (key) {
	try {
		return localStorage.getItem(key)
	} catch (error) {
		LogError(error,null)
		return null
	}
}

export const LocalStorageRemover = function (key) {
	try {
		localStorage.removeItem(key)
	} catch (error) {
		LogError(error,null)
	}
}

export const IsExist = function (item) {
	if(typeof item !== 'undefined' && item != null){
		return true
	}else{
		return false
	}
}

export const ClearIntervals = function () {
	const intervalId = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER)
	for (let i = 1; i < intervalId; i++) {
		window.clearInterval(i)
	}
}

export const ThemeIsDark = function () {
	if(typeof window !== 'undefined'){
		if(LocalStorageGetter("theme-state") !== null){
			return LocalStorageGetter("theme-state") == "dark"
		}else{
			return matchMedia("(prefers-color-scheme:dark)").matches
		}
	}
}

export const IsLogin = function () {
	if(typeof window !== 'undefined'){
		if(LocalStorageGetter("token") !== null){
			return true
		}
	}
	return false
}

export const GetUserFullName = function () {
	if(typeof window !== 'undefined'){
		if(LocalStorageGetter("userFullName") !== null){
			return LocalStorageGetter("userFullName")
		}
	}
	return null
}

export const GetUserProfileImageID = function () {
	if(typeof window !== 'undefined'){
		if(LocalStorageGetter("profileImageID") !== null){
			return LocalStorageGetter("profileImageID")
		}
	}
	return null
}

export const GetAuthToken = function () {
	if(typeof window !== 'undefined'){
		const router = useRouter()
		if(LocalStorageGetter("token") !== null){
			return LocalStorageGetter("token")
		}else{
			ShowToast("warning", "لطفا وارد حساب کاربری شوید", 8000, false, "please-login")
			router.push("/")
		}
	}
}

export const CheckTextIsOfAllowedCharacters = function (text, allowedcharacterString) {
	let allowed = true
	text.split('').forEach(character => {
		if( ! (allowedcharacterString).includes(character)){
			allowed = false
		}
	})
	return allowed
}

export const FormatPrice = function (price) {
	return Number(price).toLocaleString(undefined, { minimumFractionDigits: 0,maximumFractionDigits: 13})
}

export const RoundPercent = function (percent) {
	return Number(percent).toLocaleString(undefined, { minimumFractionDigits: 0,maximumFractionDigits: 2})
}

export const RoundPrice = function (price) {
	return Math.round(Number(price))
}

export const GenerateSvgChartQueryString = function () {
	const datetime = new Date()
	return datetime.getFullYear().toString() + "-" +
			(datetime.getMonth() + 1).toString() + "-" +
			datetime.getDate().toString() + "-" +
			datetime.getHours().toString()
}

function getDateFormat(uDate,option){
	let date = new Intl.DateTimeFormat('fa-IR', option).format(uDate)
	return date
}

export const ConvertUtcDatetimeToShamsi = function (datetime) {
	if(datetime == null){
		return ""
	}
	const IRUTCDiffirenceInMinute = 210 //3.5 hour
	let utcDateTime = new Date(datetime)
	utcDateTime.setMinutes(utcDateTime.getMinutes() + IRUTCDiffirenceInMinute)
	return(
		getDateFormat(Date.parse(utcDateTime), {"year" : "numeric"})
		+ "/" +
		getDateFormat(Date.parse(utcDateTime), {"month" : "2-digit"})
		+ "/" +
		getDateFormat(Date.parse(utcDateTime), {"day" : "2-digit"})
		+ " " +
		getDateFormat(Date.parse(utcDateTime), {"hour" : "2-digit"})
		+ ":" +
		getDateFormat(Date.parse(utcDateTime), {"minute" : "2-digit"})
		+ ":" +
		getDateFormat(Date.parse(utcDateTime), {"second" : "2-digit"})
	)
}

export const ConvertUtcDateToShamsi = function (datetime) {
	if(datetime == null){
		return ""
	}
	const IRUTCDiffirenceInMinute = 210 //3.5 hour
	let utcDateTime = new Date(datetime)
	utcDateTime.setMinutes(utcDateTime.getMinutes() + IRUTCDiffirenceInMinute)
	return(
		getDateFormat(Date.parse(utcDateTime), {"year" : "numeric"})
		+ "/" +
		getDateFormat(Date.parse(utcDateTime), {"month" : "2-digit"})
		+ "/" +
		getDateFormat(Date.parse(utcDateTime), {"day" : "2-digit"})
	)
}

export const Sleep = async function (ms) {
	await new Promise(resolve => setTimeout(resolve, ms))
	.then(function(){
		return 
 	}).catch(function (e) {
		LogError(e,null)
		return
	})
}

export function ImageLoader({ src }) {
	return src
}

Siema.prototype.addPagination = function (siema) {
	if($('.siema-pagination').length){$('.siema-pagination').remove()}
	let div = document.createElement('div')
	div.style.cssText = 'display: flex; justify-content: center; width: 100%; padding: 5px;'
	div.className = 'siema-pagination'
	let current = siema.currentSlide
	let _this = siema
	siema.innerElements.forEach(function (el, i) {
		let btn = document.createElement('span')
		btn.style.cssText = 'cursor: pointer; display: inline-flex; min-width: 10px; min-height: 10px; border-radius: 50%; margin: 5px;'
		if (i === current) btn.style.backgroundColor = 'var(--primary)'
		else btn.style.backgroundColor = 'var(--grey-normal)'
		btn.addEventListener('click', function () { _this.goTo(i) })
		div.appendChild(btn)
	})
	siema.selector.parentNode.appendChild(div)
	let btns = document.querySelectorAll('.siema-pagination span')
	btns.forEach(function (btn, i) {
		if(i > btns.length - siema.perPage){
			btn.style.display = "none"
		}
	})
}

export const InitSiema = function (selector, time, paginate, perPage) {
	let siemaInterval, siemaCallback, siema
	siemaCallback = function () {
		if (paginate && siema) {
			let current = siema.currentSlide
			let btns = document.querySelectorAll('.siema-pagination span')
			btns.forEach(function (btn, i) {
				if (i === current) btn.style.backgroundColor = 'var(--primary)'
				else btn.style.backgroundColor = 'var(--grey-normal)'
			})
		}
		if (siemaInterval) {
			clearInterval(siemaInterval)
		}
		siemaInterval = setInterval(function () {
			if(document.hidden || !siema) return
			let last = true
			siema.next(1, function () { last = false })
			setTimeout(function () {
				if(last) siema.goTo(0)
			}, 10)
		}, time)
	}
	siema = new Siema({
		selector: selector,
		perPage: perPage,
		easing: 'ease-out',
		startIndex: 0,
		draggable: true,
		multipleDrag: true,
		loop: false,
		rtl: true,
		onInit: siemaCallback,
		onChange: siemaCallback,
	})
	if (paginate) {
		siema.addPagination(siema)
		window.addEventListener('resize', function(event){
			siema.addPagination(siema)
		})
	}
	return siema
}

export const AddClassRandomTime = async function (item, className, elementID) {
	await Sleep(Math.floor(Math.random() * 1500))

	if($(elementID).length){
		document.getElementById("coin-" + item.coinID + "-item-irt-price").innerText = FormatPrice(item.lastPriceIrt / item.priceIrtUnit)
		document.getElementById("coin-" + item.coinID + "-item-usdt-price").innerText = FormatPrice(item.lastPriceUsdt)
		if(IsExist(item.priceUsdtChangePercent24H)){
			let percentBoxColor = item.priceUsdtChangePercent24H.toString().includes("-") ? "var(--red)" : "var(--green)"
			document.getElementById("coin-" + item.coinID + "-item-usdt-price-change-percent").innerText = RoundPercent(item.priceUsdtChangePercent24H)
			if($(elementID).data("styletype") == "background-color"){
				document.getElementById("coin-" + item.coinID + "-item-usdt-price-change-percent-box").style.backgroundColor = percentBoxColor
			}else if($(elementID).data("styletype") == "color"){
				document.getElementById("coin-" + item.coinID + "-item-usdt-price-change-percent-box").style.color = percentBoxColor
				document.getElementById("coin-" + item.coinID + "-item-usdt-price-change-percent-arrow-box").style.color = percentBoxColor
				if(item.priceUsdtChangePercent24H.toString().includes("-")){
					$("#coin-" + item.coinID + "-item-usdt-price-change-percent-arrow-bottom").removeClass("d-none")
					$("#coin-" + item.coinID + "-item-usdt-price-change-percent-arrow-bottom").addClass("d-block")
					$("#coin-" + item.coinID + "-item-usdt-price-change-percent-arrow-top").removeClass("d-block")
					$("#coin-" + item.coinID + "-item-usdt-price-change-percent-arrow-top").addClass("d-none")
				}else{
					$("#coin-" + item.coinID + "-item-usdt-price-change-percent-arrow-bottom").removeClass("d-block")
					$("#coin-" + item.coinID + "-item-usdt-price-change-percent-arrow-bottom").addClass("d-none")
					$("#coin-" + item.coinID + "-item-usdt-price-change-percent-arrow-top").removeClass("d-none")
					$("#coin-" + item.coinID + "-item-usdt-price-change-percent-arrow-top").addClass("d-block")
				}
			}
		}
		$(elementID).addClass(className)
	}

	await Sleep(1500)

	if($(elementID).length){
		$(elementID).removeClass("cryptocurrency-pump")
		$(elementID).removeClass("cryptocurrency-dump")
	}
}

export const CryptoCurrencyPriceUpdates = function (json) {
	$('.cryptocurrency-item-layer').each(async function(i, obj) {
		JSON.parse(json).map(function(item, index){
			if(item.coinID == $(obj).data("id")){
				const elementID = "#" + "cryptocurrency-item-layer" + item.coinID
				if($(elementID).length){
					if((item.lastPriceIrt / item.priceIrtUnit) > $(obj).data("price")){
						AddClassRandomTime(item, "cryptocurrency-pump", elementID)
					}
					if((item.lastPriceIrt / item.priceIrtUnit) < $(obj).data("price")){
						AddClassRandomTime(item, "cryptocurrency-dump", elementID )
					}
					$(obj).data("price", (item.lastPriceIrt / item.priceIrtUnit))
				}
			}
		})
	})
}

export const uiFriendlyCryptoWalletAddress = function (address) {
	try {
		if(IsExist(address) && address.length > 25){
			return address.substring(0,10) + "..." + address.substring((address.length - 10),address.length)
		}else{
			return address
		}
	} catch (error) {
		LogError(error,null)
		return ""
	}
}

export const uiFriendlyBankCardNumber = function (number) {
	try {
		if(IsExist(number) && number.length == 16){
			let finalNumber = number.substring(0,4) + "-"
			finalNumber += number.substring(4,8) + "-"
			finalNumber += number.substring(8,12) + "-"
			finalNumber += number.substring(12,16)
			return finalNumber
		}else{
			return number
		}
	} catch (error) {
		LogError(error,null)
		return ""
	}
}

export const buySellNumbersIntegerSectionCharacterLengthLimit = 10
export const buySellNumbersDecimalSectionCharacterLengthLimit = 6

export const NormalizedFormatedPrice = function (value){
	return StringReplaceAll(value.toString(),",","")
}

export const formatNumberInputValue = function (value) {
	value = ConverNumbersToEnFormat(value)
	if(CheckTextIsOfAllowedCharacters(value,(",." + Numbers))){
		if((value.split('.').length - 1) > 1){
			return null
		}
		value = NormalizedFormatedPrice(value)
		if(value.includes(".")){
			let numberIntegerPart = FormatPrice(value.slice(0,value.indexOf(".")))
			numberIntegerPart == "NaN" ? "0" : numberIntegerPart.toString()
			let numberDecimalPart = value.slice(value.indexOf("."),value.length)
			if(NormalizedFormatedPrice(numberIntegerPart).length <= buySellNumbersIntegerSectionCharacterLengthLimit && StringReplaceAll(numberDecimalPart,".","").length <= buySellNumbersDecimalSectionCharacterLengthLimit){
				let finalNumber = numberIntegerPart + numberDecimalPart
				return finalNumber == "NaN" ? null : finalNumber
			}else{
				return null
			}
		}else{
			if(value.length <= buySellNumbersIntegerSectionCharacterLengthLimit){
				return FormatPrice(value)
			}else{
				return null
			}
		}
	}else{
		return null
	}
}

export const copyToClipboard = function (value) {
	if(IsExist(value)){
		navigator.clipboard.writeText(value)
		ShowToast("success", "کپی با موفقیت انجام شد", 5000, true, null)
	}
}

export const NormalizeFormInputValue = function (value) {
	return value == "" ? null : value
}

export const StartSendButtonAnimation = function (elementId, text) {
	if(document.getElementById(elementId)){
		document.getElementById(elementId).innerText = text
	}
}

export const StopSendButtonAnimation = function (elementId, text) {
	if(document.getElementById(elementId)){
		document.getElementById(elementId).innerText = text
	}
}

export const LoadItems = function (token, getItemsUrl, setDataItems, router) {
	let loadingItemLayerItems = document.getElementsByClassName("loading-items-layer")
	const options = {
		method: 'GET', 
		headers: new Headers({
			'Authorization': token
		})
	}
	fetch(getItemsUrl, options)
	.then(async (res) => {
		if(res.status == 200){
			return res.json()
		}else if(res.status == 400){
			res.json().then(function(result) {
				const { code } = result
				const errorMessage = BadRequestErorToMessage(code,null,result,router)
			})
		}
		throw res
	})
	.then((resData) => {
		setDataItems(resData)
		if(IsExist(loadingItemLayerItems)){
			for(var item of loadingItemLayerItems){
				item.style.display = "none"
			}
		}
	})
	.catch((error) => {
		if(IsExist(loadingItemLayerItems)){
			for(var item of loadingItemLayerItems){
				item.style.display = "none"
			}
		}
		LogError(error,router)
	})
}

export const CreateItem = function (token, submitUrl, itemsPageLink ,router, postData, itemFaName, setErrorText, buttonText, buttonLoadingText) {
	StartSendButtonAnimation("create-button-text", buttonLoadingText)
	const JSONdata = JSON.stringify(postData)
	const options = {
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json',
			'Authorization': token
		}),
		body: JSONdata,
	}
	fetch(submitUrl, options)
	.then(async (res) => {
		if(res.status == 201){
			return res
		}else if(res.status == 400){
			res.json().then(function(result) {
				const { code } = result
				const errorMessage = BadRequestErorToMessage(code,null,result,router)
				if(errorMessage != ""){
					setErrorText(errorMessage)
				}
			})
		}
		throw res
	})
	.then((resData) => {
		ShowToast("success", itemFaName + " مورد نظر با موفقیت ثبت شد", 8000, true, null)
		StopSendButtonAnimation("create-button-text", buttonText)
		router.push(itemsPageLink)
	})
	.catch((error) => {
		StopSendButtonAnimation("create-button-text", buttonText)
		LogError(error,router)
	})
}

export const DeleteItem = function (token, submitUrl, itemFaName, setShowDeleteModal, deleteCounter, setDeleteCounter, router) {
	StartSendButtonAnimation("delete-button-text", deleteButtonLoadingText)
	const options = {
		method: 'DELETE', 
		headers: new Headers({
			'Authorization': token
		})
	}
	fetch(submitUrl, options)
	.then(async (res) => {
		if(res.status == 204){
			return res
		}else if(res.status == 400){
			res.json().then(function(result) {
				const { code } = result
				const errorMessage = BadRequestErorToMessage(code,itemFaName,result,router)
			})
		}
		throw res
	})
	.then((data) => {
		ShowToast("error", itemFaName + " مورد نظر حذف شد", 8000, true, null)
		StopSendButtonAnimation("delete-button-text", deleteButtonText)
		setShowDeleteModal(false)
		setDeleteCounter(deleteCounter + 1)
	})
	.catch((error) => {
		StopSendButtonAnimation("delete-button-text", deleteButtonText)
		LogError(error,router)
	})
}

export const UserCoinFavorites = function (nameFa, coinID, router) {
	if(typeof window !== 'undefined'){
		if(LocalStorageGetter("token") !== null){
			const token = LocalStorageGetter("token")
			let element = document.getElementById("favorite-item-icon-" + coinID)
			element.getAttribute("data-state") == "on" ? RemoveCoinOfUserFavorites(element, nameFa, coinID, token, router) : AddCoinToUserFavorites(element, nameFa, coinID, token, router)
		}else{
			ShowToast("warning", "برای افزودن ارز دیجیتال به لیست علاقمندی لطفا وارد حساب کاربری شوید", 8000, true, null)
		}
	}
}

function AddCoinToUserFavorites (element, nameFa, coinID, token, router) {
	const options = {
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json',
			'Authorization': token
		})
	}
	fetch((UserPanelServerUrl + "/Account/AddCoinToUserFavorites/" + coinID), options)
	.then(async (res) => {
		if(res.status == 200){
			return res
		}else if(res.status == 400){
			res.json().then(function(result) {
				const { code } = result
				const errorMessage = BadRequestErorToMessage(code,null,result,router)
			})
		}
		throw res
	})
	.then((resData) => {
		element.setAttribute("data-state","on")
		ShowToast("success", "ارز دیجیتال " + nameFa + " به لیست علاقمندی اضافه شد", 8000, true, null)
	})
	.catch((error) => {
		LogError(error,router)
	})
}

function RemoveCoinOfUserFavorites (element, nameFa, coinID, token, router) {
	const options = {
		method: 'DELETE', 
		headers: new Headers({
			'Authorization': token
		})
	}
	fetch((UserPanelServerUrl + "/Account/RemoveCoinOfUserFavorites/" + coinID), options)
	.then(async (res) => {
		if(res.status == 204){
			return res
		}else if(res.status == 400){
			res.json().then(function(result) {
				const { code } = result
				const errorMessage = BadRequestErorToMessage(code,null,result,router)
			})
		}
		throw res
	})
	.then((data) => {
		element.setAttribute("data-state","")
		ShowToast("error", "ارز دیجیتال " + nameFa + " از لیست علاقمندی حذف شد", 8000, true, null)
	})
	.catch((error) => {
		LogError(error,router)
	})
}

export const UploadRequestKyc = function (token, submitUrl, postData, router, setErrorText, buttonText, buttonLoadingText) {
	StartSendButtonAnimation("send-button-text", buttonLoadingText)
	const JSONdata = JSON.stringify(postData)
	const options = {
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json',
			'Authorization': token
		}),
		body: JSONdata,
	}
	fetch(submitUrl, options)
	.then(async (res) => {
		if(res.status == 201){
			return res
		}else if(res.status == 400){
			res.json().then(function(result) {
				const { code } = result
				const errorMessage = BadRequestErorToMessage(code,null,result,router)
				if(errorMessage != ""){
					setErrorText(errorMessage)
				}
			})
		}
		throw res
	})
	.then((resData) => {
		ShowToast("success", "درخواست احراز هویت با موفقیت ثبت شد و در حال بررسی است", 8000, true, null)
		StopSendButtonAnimation("send-button-text", buttonText)
		router.push("/panel/account")
	})
	.catch((error) => {
		StopSendButtonAnimation("send-button-text", buttonText)
		LogError(error,router)
	})
}

export const MakePreOrder = function (token, submitUrl, redirectUrlBase, postData, router, buttonText, loadingButtonText, buttonId) {
	document.getElementById(buttonId).innerText = loadingButtonText
	const JSONdata = JSON.stringify(postData)
	const options = {
		method: 'POST', 
		headers: new Headers({
			'Content-Type': 'application/json',
			'Authorization': token
		}),
		body: JSONdata,
	}
	fetch(submitUrl, options)
	.then(async (res) => {
		if(res.status == 200){
			return res.json()
		}else if(res.status == 400){
			res.json().then(function(result) {
				const { code } = result
				const errorMessage = BadRequestErorToMessage(code,null,result,router)
			})
		}
		throw res
	})
	.then((resData) => {
		document.getElementById(buttonId).innerText = buttonText
		router.push(redirectUrlBase + resData.code)
	})
	.catch((error) => {
		document.getElementById(buttonId).innerText = buttonText
		LogError(error,router)
	})
}

export const BadRequestErorToMessage = function (code, extraName, extraData, router) {
	let message = ""
	if(typeof code !== 'undefined'){
		switch (code) {
			case 1101:
				message = "رمز عبور وارد شده صحیح نمی‌باشد"
				break
			case 1102:
				message = ""
				ShowToast("error", "حساب شما محدود شده است لطفا چند دقیقه دیگر امتحان نمایید", 8000, true, null)
				break
			case 1103:
				message = "کد تایید وارد شده صحییح نمی‌باشد"
				break
			case 1104:
				message = "کد تایید وارد شده منقضی شده است"
				break
			case 1105:
				message = ""
				ShowToast("error", "خطایی در هنگام ارسال کد تایید رخ داده است لطفا دوباره امتحان نمایید", 8000, true, null)
				break
			case 1106:
				message = ""
				ShowToast("error", "امکان ارسال کد تایید قبل از منقضی شدن کد قبلی وجود ندارد لطفا چند دقیقه دیگر امتحان نمایید", 8000, true, null)
				break
			case 1107:
				message = "رمز عبور وارد شده صحیح نمی‌باشد لطفا رمز عبوری مطابق الگو وارد نمایید"
				break
			case 1108:
				message = ""
				ShowToast("error", "آی پی شما برای ورود صحیح نیست", 8000, true, null)
				break
			case 1109:
				message = ""
				ShowToast("error", "خطایی در هنگام ایجاد حساب کاربری رخ داده است لطفا دوباره امتحان نمایید", 8000, true, null)
				break
			//case 1171:
			//	message = ""
			//	ShowToast("warning", "لطفا ابتدا احراز هویت نمایید سپس برای ثبت کارت بانکی اقدام کنید", 8000, true, null)
			//	router.push("/panel/account/kyc")
			//	break
			//case 1172:
			//	message = ""
			//	ShowToast("warning", "لطفا ابتدا احراز هویت نمایید سپس برای ثبت شبای بانکی اقدام کنید", 8000, true, null)
			//	router.push("/panel/account/kyc")
			//	break
			//case 1173:
			//	message = ""
			//	ShowToast("warning", "لطفا ابتدا احراز هویت نمایید سپس برای ثبت ولت دیجیتالی اقدام کنید", 8000, true, null)
			//	router.push("/panel/account/kyc")
			//	break
			case 1174:
				message = ""
				ShowToast("warning", "لطفا ابتدا احراز هویت نمایید سپس برای ثبت درخواست برداشت وجه اقدام کنید", 8000, true, null)
				router.push("/panel/account/kyc")
				break
			case 1175:
				message = ""
				ShowToast("warning", "لطفا ابتدا احراز هویت نمایید سپس برای ثبت سفارش اقدام کنید", 8000, true, null)
				router.push("/panel/account/kyc")
				break
			case 1176:
				message = ""
				ShowToast("warning", "لطفا ابتدا احراز هویت نموده و کارت بانکی ثبت نمایید سپس برای ثبت سفارش اقدام کنید", 8000, true, null)
				router.push("/panel/account/kyc")
				break
			case 1177:
				message = ""
				ShowToast("warning", "لطفا ابتدا احراز هویت نموده و بعد از تایید شدن کارت بانکی برای ثبت سفارش اقدام کنید", 8000, true, null)
				router.push("/panel/account/kyc")
				break
			case 1178:
				message = ""
				ShowToast("warning", "لطفا ابتدا کارت بانکی ثبت نمایید سپس برای ثبت سفارش اقدام کنید", 8000, true, null)				
				router.push("/panel/cards/create")
				break
			case 1179:
				message = ""
				ShowToast("warning", "کارت بانکی شما در حال بررسی است لطفا بعد از تایید شدن برای ثبت سفارش اقدام کنید", 8000, true, null)
				break
			case 1201:
				message = ""
				ShowToast("warning", "لطفا ابتدا کارت بانکی اضافه نمایید سپس برای پرداخت اقدام کنید", 8000, true, null)
				break
			case 1202:
				message = "شماره کارت بانکی تکراری است"
				break
			case 1203:
				message = ""
				ShowToast("warning", "کارت بانکی شما در حال بررسی است لطفا بعد از تایید شدن برای پرداخت اقدام کنید", 8000, true, null)
				break
			case 1302:
				message = "شماره شبای بانکی تکراری است"
				break
			case 1303:
				message = ""
				ShowToast("warning", "لطفا ابتدا شبای بانکی اضافه نمایید سپس برای برداشت اقدام کنید", 8000, true, null)
				break
			case 1304:
				message = ""
				ShowToast("warning", "شبای بانکی شما در حال بررسی است لطفا بعد از تایید شدن برای برداشت اقدام کنید", 8000, true, null)
				break
			case 1401:
				message = "آدرس ولت دیجیتالی تکراری است"
				break
			case 2101:
				message = "آدرس آی پی ثابت وارد شده صحیح نیست"
				break
			case 2201:
				message = "کد تایید اس ام اس وارد شده صحییح نمی‌باشد"
				break
			case 2202:
				message = "کد تایید اس ام اس وارد شده منقضی شده است"
				break
			case 2203:
				message = ""
				ShowToast("error", "خطایی در هنگام ارسال کد تایید اس ام اس رخ داده است لطفا دوباره امتحان نمایید", 8000, true, null)
				break
			case 2204:
				message = ""
				ShowToast("error", "امکان ارسال کد تایید اس ام اس قبل از منقضی شدن کد قبلی وجود ندارد لطفا چند دقیقه دیگر امتحان نمایید", 8000, true, null)
				break
			case 2205:
				message = "کد تایید Google Authenticator وارد شده صحییح نمی‌باشد"
				break
			case 3101:
				message = ""
				ShowToast("error", "بانک شماره کارت وارد شده شناسایی نشد لطفا در صورت اطمینان از شماره کارت وارد شده با پشتیبانی تماس بگیرید", 8000, true, null)
				break
			case 3201:
				message = ""
				ShowToast("error", "بانک شماره شبای وارد شده شناسایی نشد لطفا در صورت اطمینان از شماره شبای وارد شده با پشتیبانی تماس بگیرید", 8000, true, null)
				break
			case 4101:
				message = ""
				var {coinName, minBuy} = extraData
				ShowToast("error", ("حداقل خرید ارز دیجیتال " + coinName + " برابر با " + FormatPrice(minBuy) + " واحد است"), 8000, true, null)
				break
			case 4102:
				message = ""
				var {maxPriceIrt} = extraData
				ShowToast("error", ("حداکثر مبلغ در هر خرید " + FormatPrice(maxPriceIrt) + " تومان است"), 8000, true, null)
				break
			case 4103:
				message = ""
				ShowToast("error", "در حال حاضر خرید ارز دیجیتال " + extraName + " غیر فعال می‌باشد", 8000, true, null)
				break
			case 4201:
				message = ""
				var {coinName, minSell} = extraData
				ShowToast("error", ("حداقل فروش ارز دیجیتال " + coinName + " برابر با " + FormatPrice(minSell) + " واحد است"), 8000, true, null)
				break
			case 4202:
				message = ""
				var {maxPriceIrt} = extraData
				ShowToast("error", ("حداکثر مبلغ در هر فروش " + FormatPrice(maxPriceIrt) + " تومان است"), 8000, true, null)
				break
			case 4203:
				message = ""
				ShowToast("error", "در حال حاضر فروش ارز دیجیتال " + extraName + " غیر فعال می‌باشد", 8000, true, null)
				break
			case 4301:
				message = ""
				var {maxWithdrawal} = extraData
				ShowToast("error", "حداکثر مجموع مبلغ برداشت روزانه برای سطح حساب کاربری شما " + FormatPrice(maxWithdrawal) + " تومان می‌باشد لطفا سطح حساب کاربری خود را ارتقا دهید", 8000, true, null)
				break
			case 4401:
				message = ""
				var {maxOrder} = extraData
				ShowToast("error", "حداکثر مجموع مبلغ سفارش روزانه برای سطح حساب کاربری شما " + FormatPrice(maxOrder) + " تومان می‌باشد لطفا سطح حساب کاربری خود را ارتقا دهید", 8000, true, null)
				break
			case 4501:
				message = ""
				ShowToast("error", "موجودی کیف پول ریالی شما کافی نیست لطفا کیف پول ریالی خود را شارژ نمایید سپس برای ثبت سفارش اقدام کنید", 8000, true, null)
				router.push("/panel/irt-wallet")
				break
			case 5101:
				message = ""
				router.push("/buy/payment-irt/" + extraData.orderCode)
				break
			case 5201:
				message = ""
				router.push("/sell/payment-crypto-currency/" + extraData.orderCode)
				break
			case 5501:
				message = ""
				ShowToast("error", "سفارش شما منقضی شده است لطفا دوباره برای ثبت سفارش اقدام کنید", 8000, true, null)
				router.push("/")
				break
			case 6101:
				message = ""
				ShowToast("error", "آدرس ولت دیجیتالی وارد شده صحیح نیست لطفا در صورت اطمینان از آدرس وارد شده با پشتیبانی تماس بگیرید", 8000, true, null)
				break
			case 7101:
				message = ""
				ShowToast("error", "خطایی در هنگام انتقال به درگاه بانکی رخ داده است لطفا دوباره امتحان نمایید", 8000, true, null)
				break
			case 8101:
				message = ""
				ShowToast("warning", "واریز ارز انجام نشده است لطفا بعد از واریز و تایید شبکه دوباره امتحان نمایید", 8000, true, null)
				break
			case 8201:
				message = ""
				ShowToast("error", "مشکلی در هنگام ایجاد سفارش فروش رخ داده است لطفا دوباره امتحان نمایید", 8000, true, null)
				break
			case 204:
				message = ""
				ShowToast("error", "امکان حذف " + extraName + " مورد نظر وجود ندارد", 8000, true, null)
				break
		}
	}
	return message
}
