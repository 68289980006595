import Head from "next/head";
import {Component, ErrorInfo, ReactNode} from "react";
import {CompanyName, LogError} from "../../config";

export class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		console.log(errorInfo);
		LogError(error,null);
	}

	render() {
		if (this.state.hasError) {
			return (
				<>
					<Head>
						<title>صرافی {CompanyName} | خطایی موقع نمایش این بخش رخ داد!</title>
					</Head>
					<div className="container d-flex justify-content-center align-items-center w-100 h-100">
						<div className="row">
							<div className="col-md-12 mb-5">
								<div className="text-center mb-0" style={{fontSize: "10rem", fontWeight: "bold", color:"var(--primary)"}}>500</div>
								<h4 className="text-center mb-0">خطایی موقع نمایش این بخش رخ داد! لطفا بعدا دوباره تلاش نمایید.</h4>
							</div>
						</div>
					</div>
					<style jsx global>{`
						body{
							width: 100vw;
							height: 100vh;
							overflow: hidden;
						}
						body>:first-child{
							width: 100%;
							height: 100%;
						}
					`}</style>
				</>
			);
		}

		return this.props.children;
	}
}
